import { Component } from 'react';
import ApiService from "../services/api.service";

export default class ErrorBoundary extends Component {

    state = {
        hasError: global.config === 'NOT_ALLOWED_DOMAIN' || global.config === 'SERVER_NO_ANSWER' ? true : false,
        errormessage: global.config === 'NOT_ALLOWED_DOMAIN' || global.config === 'SERVER_NO_ANSWER' ? global.config : ''
    };

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            errormessage: error
        };
    }

    componentDidCatch(error) {
        if (!this.state.hasError) {
            if (error.name === 'AxiosError') {
                this.setState({ errormessage: error.code });
            }
            else {
                this.setState({ errormessage: error });
            }
        }
    }

    render() {

        if (!navigator.userAgent.includes('Deepscan')) {

            if (this.state.hasError) {
                let contentsection;
                let reacterror = false;
                switch (this.state.errormessage) {
                    case 'NOT_ALLOWED_DOMAIN':
                        contentsection = <p><strong>Dieser Seite fehlt die nötige Berechtigung!</strong><br />Um den gewünschten Shop anzuzeigen, muss ein Administrator kontaktiert werden.</p>
                        break;
                    case 'ERR_NETWORK':
                        contentsection = <p><strong>Die Verbindung zu unserem Server wurde leider unterbrochen.</strong><br />In wenigen Minuten sollte alles wieder funktionieren.</p>
                        break;
                    case 'NO_IDENTIFIER':
                        contentsection = <p><strong>Diese Seite ist nicht ohne gültige Kennung aufrufbar.</strong><br />Der Vorgang muss erneut ausgeführt werden.</p>
                        break;
                    case 'SERVER_NO_ANSWER':
                        contentsection = <p>Der Server hat leider keine Antwort geliefert.</p>
                        break;
                    case 'INTERN_SHOP_NOT_SUPPORTED':
                        contentsection = <p>Der Shop ist nicht für den Vorort-Verkauf freigeschaltet. Bitte kontaktieren Sie unser Supportteam.</p>
                        break;
                    case 'EXCHANGE_NOT_SUPPORTED':
                        contentsection = <p>Das Vorort-Umtauschprodukt wurde noch nicht konfiguriert oder ist deaktiviert.</p>
                        break;
                    case 'NO_CONNECTION':
                        contentsection = <p>Derzeit ist leider keine Verbindung zu unserem Server möglich.<br />In wenigen Minuten sollte alles wieder funktionieren.</p>
                        break;
                    default:
                        reacterror = true
                        contentsection = <p>Wir wurden bereits informiert und versuchen den Fehler schnellstmöglich zu beheben.</p>
                        break;
                }

                if (reacterror && typeof (global.config.express) !== 'undefined') {
                    const API_URL = global.config.express.url + '/senderrormail';
                    const API_DATA = {
                        'url': window.location.href,
                        'error': this.state.errormessage.message,
                        'errorstack': this.state.errormessage.stack
                    }
                    ApiService.getData(API_URL, API_DATA);
                }

                return (
                    <div className="container t-center">
                        <h1>Ein Fehler ist aufgetreten</h1>
                        {contentsection}
                    </div>
                );
            }
            return this.props.children;

        }

    }

}
