import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';
import ApiService from "../../../services/api.service";
import * as GlobalStore from '../../../store/global/actions';
import * as ShoppingCartStore from '../../../store/shoppingcart/actions';
import { Fragment, useRef, useState } from 'react';

const ProductPaymentButtons = ({ couponActivePrice, dynamicPrice }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const payments = useSelector(state => state.productpage.payments);
    const contingent = useSelector(state => state.productpage.productdata.contingent);
    const minqty = useSelector(state => state.productpage.productdata['minimum-quantity']);
    const cart = useSelector(state => state.cart);
    const useshoppingcart = useSelector(state => state.global.useshoppingcart);
    const coupon = useSelector(state => state.productpage.coupon);
    const activeProductData = useSelector(state => state.productpage.productdata);
    const isDynamicProduct = useSelector(state => state.productpage.data.products.dynamic);

    const overlayRef = useRef(null);
    const [openOverlay, setOpenOverlay] = useState(false);
    const [overlayContent, setOverlayContent] = useState();



    let paymentButtons = [];
    if (contingent === 0 || (contingent !== null && minqty > contingent)) {
        paymentButtons = <button type="button" className="setorder soldout">Leider ausverkauft</button>
    }

    else {
        let fastcheckoutproviders = [];
        let cartproviders = [];
        function setOrder(e, provider) {
            if (provider.active) {

                if (global.config.preview) {
                    alert('Diese Funktion ist in der Vorschau nicht verfügbar.');
                    return false;
                }

                e.target.setAttribute('disabled', true);

                let botfield1;
                let botfield2;
                if (e.target.form) {
                    botfield1 = e.target.form[0].value;
                    botfield2 = e.target.form[1].value;
                }
                else {
                    botfield1 = e.target.parentElement.form[0].value;
                    botfield2 = e.target.parentElement.form[1].value;
                }
                if (botfield1 !== '' || botfield2 !== 'A17B13') {
                    const API_URL = global.config.express.url + '/botfieldchange';
                    const API_DATA = {
                        'url': window.location.href,
                        'agent': "Geänderte Felder bei Submit erkannt.\n\n" + navigator.userAgent
                    }
                    ApiService.getData(API_URL, API_DATA).then((response) => {
                        if (response) {
                            navigate('?path=bestellung-fehlgeschlagen', { replace: true });
                        }
                    });
                    return false;
                }

                let payment;

                payment = {
                    'payment-tupel': {
                        'payment-provider': provider['payment-provider'],
                        'payment-method-category': provider['payment-method-category'],
                        'payment-method-value': provider['payment-method-value'],
                        'payment-kind': provider['payment-kind'],
                    }
                };

                Object.assign(cart, payment);

                const API_URL = global.config.express.url + '/cart/transfer';
                const API_DATA = {
                    'shop-url': global.config.apidata.shopurl,
                    'cart': cart,
                    'framepage': window.location.origin + window.location.pathname + '?mode=react'
                }

                ApiService.getData(API_URL, API_DATA).then((response) => {

                    if (response.status === 'success') {
                        if (response.url) {
                            window.location.replace(response.url);
                        }
                        if (response.script) {
                            const script = document.createElement('script');
                            script.innerHTML = response.script;
                            document.getElementsByTagName('head')[0].appendChild(script);
                            const overlay = document.createElement('div');
                            overlay.classList.add('gw_overlay');
                            overlay.innerHTML = '<div class="infobox"><h2>Amazon Pay</h2><p>Mit Amazon Pay können Sie die in Ihrem Amazon-Account hinterlegten Zahlungs- und Versandinformationen nutzen, um schnell und sicher einzukaufen. Zudem genießen Sie den Käuferschutz, den Amazon mit der A-Z Garantie bietet.</p></div>';
                            document.getElementsByTagName('body')[0].appendChild(overlay);
                            document.getElementsByClassName('infobox')[0].appendChild(document.getElementById('AmazonPayButton'));
                            document.getElementById('AmazonPayButton').style.display = 'block';
                            var isMobileSafari = !!window.navigator.userAgent.match(/iPad/i) || !!window.navigator.userAgent.match(/iPhone/i);
                            setTimeout(function () {
                                var infobox = document.getElementsByClassName('infobox')[0];
                                var top = window.innerHeight / 2 - (infobox.offsetHeight / 2 + 50);
                                if (isMobileSafari) {
                                    infobox.style.width = '100%';
                                    infobox.style.left = '0';
                                    infobox.style.top = top + 'px';
                                }
                                else {
                                    var left = window.innerWidth / 2 - infobox.offsetWidth / 2;
                                    infobox.style.left = left + 'px';
                                    infobox.style.top = top + 'px';
                                }
                                infobox.style.opacity = 1;
                            }, 1000);
                        }
                    }

                    else {
                        dispatch(GlobalStore.setApiError(response.message));
                        navigate('?path=bestellung-fehlgeschlagen', { replace: true });
                    }

                });
            }
        }

        const addToShoppingCart = () => {
            dispatch(ShoppingCartStore.addToShoppingCart(cart));
            let overlaycontent =
                <>
                    <h2>Produkt erfolgreich hinzugefügt</h2>
                    <p>Das Produkt befindet sich jetzt im Warenkorb.</p>
                    <Link className='button continue-shopping' to="?path=start">Weiter shoppen</Link>
                    <Link to="?path=warenkorb" className="button">Zum Warenkorb</Link>
                </>;
            setOverlayContent(overlaycontent)
            setOpenOverlay(true);
        };


        if (isDynamicProduct && dynamicPrice === null) {
            paymentButtons.push(
                <Fragment key="nodynamicprice" >
                    <span className="or"><span>Wert erforderlich</span></span>
                    <label className='t-center'>Bitte geben Sie einen Wert ein!</label>
                </Fragment>
            )
        }
        else {

            if (activeProductData.price === 0 || (couponActivePrice === 0 && coupon.shippingpackagingprice === 0)) {

                let subproductcountselected = false;

                if (activeProductData.subproducts) {
                    activeProductData.subproducts.forEach((sp) => {
                        if (sp.amount >= 1) {
                            subproductcountselected = true;
                        }
                    })
                }

                if (activeProductData.price !== 0 || dynamicPrice === null || (activeProductData.price === 0 && subproductcountselected)) {


                    if (!useshoppingcart) {
                        paymentButtons.push(
                            <span key="or1" className="or"><span>direkt bestellen</span></span>
                        )
                        paymentButtons.push(
                            <Link key="checkout" to="?path=kasse">
                                <button type="button" className="setorder checkout">Jetzt bestellen</button>
                            </Link>
                        )
                    }
                    else {
                        paymentButtons.push(
                            <span key="or1" className="or"><span> </span></span>
                        )
                        paymentButtons.push(
                            <button key="addtoshoppingcart" type="button" className="setorder addtoshoppingcart" onClick={() => addToShoppingCart()}>In den Warenkorb</button>
                        )
                    }


                }
                else {
                    paymentButtons.push(
                        <Fragment key="noproduct" >
                            <span className="or"><span>Menge erforderlich</span></span>
                            <label className='t-center'>Bitte mindestens ein Produkt auswählen!</label>
                        </Fragment>
                    )
                }
            }

            else {
                payments.forEach((payment) => {
                    payment['payment-call-tupel'].forEach((item, i) => {
                        if (item['payment-kind'] === 'Order') {
                            item.active = payment.active;
                            fastcheckoutproviders.push(item);
                        }
                        else {
                            cartproviders.push(item);
                        }
                    });
                });

                if (fastcheckoutproviders.length) {
                    paymentButtons.push(
                        <span key="or1" className="or"><span>direkt bezahlen mit</span></span>
                    )
                    fastcheckoutproviders.forEach((provider, key) => {
                        let type = provider['payment-provider'];
                        let active = provider.active ? '' : ' inactive';
                        let paymenticon;
                        if (provider['payment-provider-icon'] && provider['payment-provider-icon']['relative-path']) {
                            paymenticon = <img src={global.config.apidata.url + '/' + provider['payment-provider-icon']['relative-path']} alt={provider['payment-provider-icon']['alt']} />
                        }
                        else {
                            paymenticon = provider['payment-name'];
                        }
                        paymentButtons.push(
                            <button
                                type="button"
                                onClick={(e) => setOrder(e, provider)}
                                className={'setorder ' + type + active}
                                key={key}
                            >
                                {paymenticon}
                            </button>
                        );
                    });
                }

                if (!useshoppingcart) {
                    if (cartproviders.length) {
                        if (fastcheckoutproviders.length) {
                            paymentButtons.push(
                                <span key="or2" className="or"><span>oder andere Zahlungsmittel</span></span>
                            )
                        }
                        else {
                            paymentButtons.push(
                                <span key="or2" className="or"><span>Jetzt bezahlen</span></span>
                            )
                        }
                        paymentButtons.push(
                            <Link key="checkout" to="?path=kasse">
                                <button type="button" className="setorder checkout">Zur Kasse</button>
                            </Link>
                        )
                    }
                }
                else {
                    if (!global.config.backendshop) {
                        paymentButtons.push(
                            <span key="or2" className="or"><span>oder</span></span>
                        )
                    }
                    paymentButtons.push(
                        <button key="addtoshoppingcart" type="button" className="setorder addtoshoppingcart" onClick={() => addToShoppingCart()}>In den Warenkorb</button>
                    )
                }
            }
        }

    }

    return (
        <>
            {paymentButtons}
            {openOverlay ?
                <div className="gw_slide_overlay">
                    <div className="infobox" ref={overlayRef}>
                        {overlayContent}
                    </div>
                </div>
                : ''}
        </>
    );


}

export default ProductPaymentButtons;
